import { createApp } from 'vue'
// import { ipcRenderer } from "electron"
import 'bootstrap/dist/css/bootstrap.css'
//import 'element-plus/lib/theme-chalk/index.css';
import 'element-plus/dist/index.css'
import ElementPlus from 'element-plus';
import locale from 'element-plus/lib/locale/lang/de'
import router from './router'
import store from './store'
import DacyServer from "./dacyserver";
import axios from 'axios'
import moment from 'moment';
import App from './App'
import {createAuth}          from '@websanova/vue-auth';
import driverAuthBearer      from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js';
import driverHttpAxios       from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js';
// global components
import PatientSearch from './components/PatientSearch'
import PatientDiagnosis from './components/PatientDiagnosis'
import PatientTherapies from './components/PatientTherapies'
import PatientSideEffects from './components/PatientSideEffects'

function initializeApp(result) {
  DacyServer.getInstance().setService(result.data)
  axios.defaults.baseURL = DacyServer.getInstance().getURL()

  var auth = createAuth({
    plugins: {
      http: axios,
      router: router
    },
    drivers: {
      http: driverHttpAxios,
      auth: driverAuthBearer,
      router: driverRouterVueRouter,
    },
    options: {
      rolesKey: 'roles_slug',
      tokenDefaultKey: 'dacy-token',
      loginData: {
        url: 'auth/login',
        fetchUser: false,
        redirect: false,
        staySignedIn: true
      },
      refreshData: {
        enabled: false
      },
      fetchData: {
        url: process.env.VUE_APP_FOR_PUBLIC_USER ? 'public/auth/user' : 'auth/user'
      },
      parseUserData: function (data) {
        return data;
      },
      forbiddenRedirect: '/login'
    }
  });


  const app = createApp(App)
  app.use(router)
  app.use(store)
  app.use(ElementPlus, { locale })
  app.use(auth)

  app.axios = axios;
  app.$http = axios;

  app.config.globalProperties.axios = axios;
  app.config.globalProperties.$http = axios;
  app.config.globalProperties.$filters = {
    germanDate(value, withTime = false, precision = 'd') {

      moment.locale('de')
      let formatDate
      switch(precision) {
        case 'd':
          formatDate = 'DD.MM.YY'
          break
        case 'm':
          formatDate = 'MMMM YYYY'
          break
        case 'y':
          formatDate = 'YYYY'
          break
      }

      return (moment(value).isValid() ? (withTime ? moment(value).format(formatDate + " HH:mm:ss") : moment(value).format(formatDate)) : '')
    }
  }

  app.component('patient-search', PatientSearch)
  app.component('patient-diagnosis', PatientDiagnosis)
  app.component('patient-therapies', PatientTherapies)
  app.component('patient-sideeffects', PatientSideEffects)

  const appcontainer = app.mount('#app')
  appcontainer.setSocketListener()

  if(result.data.ip === null) {
    router.push({
      name: 'client-settings'
    });
  }

}

if(process.env.VUE_APP_API_PROTOCOL && process.env.VUE_APP_API_HOST && process.env.VUE_APP_API_PORT) {
  initializeApp( { data: { protocol: process.env.VUE_APP_API_PROTOCOL, host: process.env.VUE_APP_API_HOST, port: process.env.VUE_APP_API_PORT } } )
}
else {
  axios({ url: 'dacy-server', baseURL: 'http://127.0.0.1:3333' })
    .then(result=> {
      initializeApp(result)
    })
}
